import * as styles from "./featuresBoxes.module.scss";
import React from "react";

import userverification from "./images/userverification.png";
import compliance from "./images/compliance.png";
import data from "./images/data.png";
import protection from "./images/protection.png";
import { useTranslation } from "react-i18next";

const FeaturesBoxes = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.featuresBoxesWrapper}>
      <div className={styles.featureBox}>
        <div className={styles.boxIconWrapper}>
          <img src={userverification} alt="" className={styles.boxIcon} />
          <div className={styles.smallDot1 + " " + styles.type1} />
          <div className={styles.bigDot1 + " " + styles.type1} />
        </div>
        <h3 className={styles.boxHeader}>{t("security.feature.1.title")}</h3>
        <p className={styles.boxDescription}>{t("security.feature.1.copy")}</p>
      </div>
      <div className={styles.featureBox}>
        <div className={styles.boxIconWrapper}>
          <img src={compliance} alt="" className={styles.boxIcon} />
          <div className={styles.smallDot2 + " " + styles.type2} />
          <div className={styles.bigDot2 + " " + styles.type2} />
        </div>
        <h3 className={styles.boxHeader}>{t("security.feature.2.title")}</h3>
        <p className={styles.boxDescription}>{t("security.feature.2.copy")}</p>
      </div>
      <div className={styles.featureBox}>
        <div className={styles.boxIconWrapper}>
          <img src={data} alt="" className={styles.boxIcon} />
          <div className={styles.smallDot3 + " " + styles.type4} />
          <div className={styles.bigDot3 + " " + styles.type4} />
        </div>
        <h3 className={styles.boxHeader}>{t("security.feature.3.title")}</h3>
        <p className={styles.boxDescription}>{t("security.feature.3.copy")}</p>
      </div>
      <div className={styles.featureBox}>
        <div className={styles.boxIconWrapper}>
          <img src={protection} alt="" className={styles.boxIcon} />
          <div className={styles.smallDot4 + " " + styles.type3} />
          <div className={styles.bigDot4 + " " + styles.type3} />
        </div>
        <h3 className={styles.boxHeader}>{t("security.feature.4.title")}</h3>
        <p className={styles.boxDescription}>{t("security.feature.4.copy")}</p>
      </div>
    </div>
  );
};

export default FeaturesBoxes;
