// extracted by mini-css-extract-plugin
export var featuresBoxesWrapper = "featuresBoxes-module--featuresBoxesWrapper--vW3vy";
export var featureBox = "featuresBoxes-module--featureBox--27EnK";
export var boxIcon = "featuresBoxes-module--boxIcon--30DWq";
export var boxHeader = "featuresBoxes-module--boxHeader--2BuNe";
export var boxDescription = "featuresBoxes-module--boxDescription--2wrr-";
export var boxIconWrapper = "featuresBoxes-module--boxIconWrapper--Lrt2r";
export var smallDot = "featuresBoxes-module--smallDot--1D-bk";
export var bigDot = "featuresBoxes-module--bigDot--Xde-m";
export var smallDot1 = "featuresBoxes-module--smallDot1--1sHDf";
export var moveSmall1 = "featuresBoxes-module--moveSmall1--24HPz";
export var bigDot1 = "featuresBoxes-module--bigDot1--ZGmlB";
export var moveBig1 = "featuresBoxes-module--moveBig1--1Fgv2";
export var smallDot2 = "featuresBoxes-module--smallDot2--13NGx";
export var moveSmall2 = "featuresBoxes-module--moveSmall2--167pu";
export var bigDot2 = "featuresBoxes-module--bigDot2--29AvU";
export var moveBig2 = "featuresBoxes-module--moveBig2--15x8n";
export var smallDot3 = "featuresBoxes-module--smallDot3--3JhLw";
export var moveSmall3 = "featuresBoxes-module--moveSmall3--4bb20";
export var bigDot3 = "featuresBoxes-module--bigDot3--1CCsj";
export var moveBig3 = "featuresBoxes-module--moveBig3--1e-Mb";
export var smallDot4 = "featuresBoxes-module--smallDot4--2RtNV";
export var moveSmall4 = "featuresBoxes-module--moveSmall4--1BWxE";
export var bigDot4 = "featuresBoxes-module--bigDot4--2hXv2";
export var moveBig4 = "featuresBoxes-module--moveBig4--3x7l-";
export var type1 = "featuresBoxes-module--type1--60lP7";
export var type2 = "featuresBoxes-module--type2--2snHm";
export var type3 = "featuresBoxes-module--type3--qOQAT";
export var type4 = "featuresBoxes-module--type4--tNVwP";
export var moveSmall = "featuresBoxes-module--moveSmall--31lmJ";
export var moveBig = "featuresBoxes-module--moveBig--1hFBc";