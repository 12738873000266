import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageHeader from "../components/PageHeader/pageHeader";
import FeaturesBoxes from "../components/security/FeaturesBoxes/featuresBoxes";
import { useTranslation } from "react-i18next";

const SecurityPage = () => {
  const { t } = useTranslation();
  return (
    <Layout menuColor={"black"}>
      <SEO title={t("menu.security")} />
      <PageHeader title={t("security.header")} />
      <FeaturesBoxes />
    </Layout>
  );
};

export default SecurityPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
